
const facebookAppId = "806143670221058"

export function initFacebookSdk() {
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v15.0'
            });

        };

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));  
        
          // Conversion tracking code
          // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: 'your-facebook-app-id', feature: 'whatsapp_embedded_signup'});
          
          // Launch Facebook login
     
    });

}
export function login_facebook(){
  window.FB.login(function (response) {
    if (response.authResponse) {
      const accessToken = response.authResponse.accessToken;
      //Use this token to call the debug_token API and get the shared WABA's ID
    } else {
      console.log('User cancelled login or did not fully authorize.');
    }
  }, {
    scope: 'business_management,whatsapp_business_management',
    extras: {
      feature: 'whatsapp_embedded_signup',
      setup: {
      }
    }
  });

}